














import { Component, Prop, Vue } from "vue-property-decorator";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

@Component({
  components: { DateRangePicker },
})
export default class DaterangePicker extends Vue {
  @Prop() onDateRangeChange!: Function;
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  updateValues() {
    this.onDateRangeChange(this.dateRange);
  }
}
